import React from 'react'
import 'focus-visible'
import '../../styles/site.scss'
import { Navigation } from './navigation'

export const Layout = ({ children, location }) => {
    return (
        <>
            <Navigation />
            <main>{children}</main>
        </>
    )
}

export default Layout