import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

export const Navigation = ({ cssClass }) => {
    const [showNav, setShowNav] = useState(false);
    const toggleNav = () => {
        setShowNav(!showNav);
    }
    const hideNav = () => {
        setShowNav(false);
    }
    return (
        <>
            <Helmet
                bodyAttributes={{
                    class: showNav ? 'is-nav-open' : ''
                }}
            />
            <nav className="c-navbar">
                <div className="container-fluid">
                    <div className="row">
                        <div className="c-navbar__header">
                            <Link to="/" className="c-navbar__brand" onClick={hideNav}>
                                <svg viewBox="0 0 58 58"><path fill="#ffffff" d="m16.74 17.493 1.17 7.04h.03l1.231-7.04h2.94l-2.43 11.786h-3.105L15.36 22.38h-.03l-1.184 6.9h-3.135l-2.43-11.787h3.06l1.23 7.04h.044l1.14-7.04h2.685ZM30.437 29.28h-7.455V17.494h7.425v2.983h-4.47v1.552h3.96v2.647h-3.96v1.623h4.5v2.983-.002ZM31.022 26.74l2.625-1.342c.165.9.945 1.359 1.754 1.359.63 0 1.155-.3 1.155-.883 0-1.623-5.175-.14-5.175-4.64 0-2.524 1.92-3.954 4.14-3.954 2.22 0 3.614.988 4.065 2.895l-2.7.918c-.135-.776-.765-1.094-1.364-1.094-.645 0-1.035.353-1.035.865 0 1.623 5.19.123 5.19 4.729 0 2.611-2.054 3.898-4.304 3.898-2.25 0-3.795-1.023-4.35-2.752h-.001ZM43.426 29.28v-8.805h-3.044v-2.982h9.165v2.982h-3.045v8.805H43.426ZM8.753 39.651l2.625-1.341c.165.9.945 1.359 1.754 1.359.63 0 1.155-.3 1.155-.883 0-1.623-5.176-.14-5.176-4.64 0-2.524 1.92-3.954 4.14-3.954 2.22 0 3.615.989 4.066 2.895l-2.7.918c-.135-.776-.765-1.094-1.364-1.094-.645 0-1.036.353-1.036.865 0 1.623 5.19.123 5.19 4.729 0 2.611-2.055 3.898-4.305 3.898-2.25 0-3.794-1.023-4.35-2.752ZM21.577 30.405V42.19h-3.074V30.405h3.074ZM22.898 30.405h4.14c3.044 0 5.145 1.87 5.145 5.893 0 4.022-2.114 5.893-5.146 5.893h-4.14V30.405Zm3.074 8.787h.87c1.306 0 2.161-.9 2.161-2.894 0-1.994-.855-2.895-2.16-2.895h-.871V39.192ZM40.778 42.19h-7.455V30.406h7.425v2.982h-4.47v1.553h3.96v2.647h-3.96v1.623h4.5v2.982-.001Z" /><path fill="#1B1B1B" d="M29 57.445C13.316 57.445.556 44.684.556 29 .556 13.316 13.315.556 29 .556 44.685.556 57.444 13.316 57.444 29S44.684 57.445 29 57.445Zm0-53.508C15.18 3.937 3.937 15.181 3.937 29c0 13.82 11.244 25.063 25.063 25.063 13.82 0 25.063-11.243 25.063-25.063C54.063 15.18 42.819 3.937 29 3.937Z" /></svg>
                                <span className="u-visually-hide">Westside Studio</span>
                            </Link>
                            <div className="c-navbar__menu-container">
                                <button className="c-navbar__menu" type="button" onClick={toggleNav}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span className="u-visually-hide">Menu</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="c-navbar__items">
                    <ul className="c-navbar__list">
                        <li className="c-navbar__item">
                            <Link to="/directors" className="c-navbar__link" onClick={toggleNav}>Directors</Link>
                        </li>
                        <li className="c-navbar__item">
                            <Link to="/photographers" className="c-navbar__link" onClick={toggleNav}>Photographers</Link>
                        </li>
                        <li className="c-navbar__item">
                            <Link to="/about" className="c-navbar__link" onClick={toggleNav}>About us</Link>
                        </li>
                        <li className="c-navbar__item">
                            <Link to="/contact" className="c-navbar__link" onClick={toggleNav}>Contact</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}